import {
  setAccessToken,
  setRefreshToken,
  setEduBureauId,
  getAccessToken,
  getRefreshToken,
  getEduBureauId,
  delDataStageDetail
} from '@/utils/storage'
import { $request } from '@/api'

let refreshTimer = null
let loginInfoStation = null

export default {
  state: {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    userInfo: null,
    eduBureauInfo: null,
    eduBureauId: getEduBureauId(),
    manageEduBureauList: [],
    semesterInfo: null,
    semesterList: []
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken
      setAccessToken(accessToken)
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
      setRefreshToken(refreshToken)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setEduBureauInfo(state, eduBureauInfo) {
      state.eduBureauInfo = eduBureauInfo
    },
    setEduBureauId(state, id) {
      state.eduBureauId = id
      setEduBureauId(id)
    },
    setManageEduBureauList(state, list) {
      state.manageEduBureauList = list
    },
    setSemesterInfo(state, info) {
      state.semesterInfo = info
    },
    setSemesterList(state, list) {
      state.semesterList = list
    }
  },
  actions: {
    login({ commit, dispatch }, { loginFunc, data }) {
      return new Promise((resolve, reject) => {
        $request(loginFunc, data).then(res => {
          if (res.success) {
            const data = res.data
            if (data.userType === 'EDU_BUREAU') {
              dispatch('loginSuccess', data)
            } else {
              dispatch('loginPause', data)
            }
            resolve(data)
          } else {
            reject()
          }
        }).catch(err => reject(err))
      })
    },
    wxLogin({ commit, dispatch }, data) {
      return new Promise((resolve) => {
        dispatch('loginSuccess', data)
        resolve()
      })
    },
    loginPause(_, data) {
      loginInfoStation = data
    },
    loginContinue({ dispatch }) {
      dispatch('loginSuccess', loginInfoStation)
      loginInfoStation = null
    },
    loginSuccess({ commit, dispatch, state }, data) {
      commit('setAccessToken', data.accessToken)
      commit('setRefreshToken', data.refreshToken)
    },
    getUserInfo({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        $request('getUserInfo').then(async res => {
          if (res.success) {
            commit('setUserInfo', res.data)
            await dispatch('getEduBureauInfo')
            await dispatch('gradeAndSubject/getSubjectList')
            dispatch('startTimer')
            resolve()
          } else {
            reject()
          }
        }).catch(err => reject(err))
      })
    },
    async getEduBureauInfo({ state, commit }) {
      const { userType } = state.userInfo
      let eduBureauId = ''
      if (userType === 'EDU_BUREAU') {
        eduBureauId = state.userInfo.eduBureauId
      } else if (userType === 'SYS_USER') {
        if (state.eduBureauId) {
          eduBureauId = state.eduBureauId
        } else {
          return Promise.reject()
        }
      } else {
        return Promise.reject()
      }
      await $request('eduBureauDetail', {}, { dynamicUrl: '/' + eduBureauId }).then(res => {
        if (res.success) {
          commit('setEduBureauInfo', res.data)
          commit('setEduBureauId', res.data.id)
        }
      })
      await $request('getSemesterList', { cityId: state.eduBureauInfo.cityId }).then(res => {
        const semesterList = res.data.map(i => {
          return {
            id: i.id,
            name: `${i.year}-${i.semesterName}`,
            current: Boolean(i.currentSemester),
            start: i.semesterStartDate,
            end: i.semesterEndDate
          }
        }).sort((a, b) => b.id - a.id)
        const semester = semesterList.find(i => i.current)
        commit('setSemesterInfo', semester)
        commit('setSemesterList', semesterList)
      })
    },
    getUserManageEduBureauList({ state, commit }) {
      let userId = ''
      let accessToken = ''
      if (state.userInfo) {
        userId = state.userInfo.userId
      } else if (loginInfoStation) {
        userId = loginInfoStation.userId
        accessToken = 'Bearer ' + loginInfoStation.accessToken
      } else {
        return
      }
      return $request('userManageEduBureauList', { userId, pageSize: 50 }, { Authorization: `${accessToken}` }).then(res => {
        if (res.success) {
          commit('setManageEduBureauList', res.data.records.map(i => ({ name: i.eduBureauName, id: i.resourceId })))
        }
      }).catch(() => {})
    },
    refreshToken({ commit, dispatch, state }, getInfo = true) {
      return new Promise((resolve, reject) => {
        $request('refreshToken', {}, { headers: { RefreshToken: state.refreshToken } }).then(async res => {
          if (res.success) {
            const data = res.data
            commit('setAccessToken', data.accessToken)
            commit('setRefreshToken', data.refreshToken)
            if (getInfo) await dispatch('getUserInfo')
            resolve()
          } else {
            reject()
          }
        }).catch(err => reject(err))
      })
    },
    startTimer({ dispatch }) {
      clearTimeout(refreshTimer)
      console.log('定时刷新开启')
      refreshTimer = setInterval(() => { // 3分钟一次自动刷新用户token
        dispatch('refreshToken', false)
      }, 180000)
    },
    logoutActive({ dispatch }) {
      dispatch('logoutPassive')
    },
    logoutPassive({ commit }) {
      commit('setAccessToken', '')
      commit('setRefreshToken', '')
      commit('setEduBureauId', '')
      commit('setUserInfo', null)
      commit('clearEvent')
      clearTimeout(refreshTimer)
    }
  }
}
